import React from "react";
/** @jsx jsx */
import { Box, jsx, useThemeUI } from "theme-ui";
import {
  Container,
  Flex,
  FlexGrid,
  Heading,
  Text,
  AspectRatio,
  Button,
} from "../../components/core";
import Page from "../../components/Page";
import Contact from "../../components/Contact";
import { Parallax } from "../../components/animate";
import { alpha } from "@theme-ui/color";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import { Card } from "../../components/Card";
import { getImage } from "gatsby-plugin-image";
import { ReactMarkdown } from "../../components/ReactMarkdown";

const NosActions = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const { theme } = useThemeUI();

  return (
    <Page sx={{ backgroundColor: "background3" }}>
      <SEO
        title="Nos actions"
        description="Retrouvez l'ensemble des activités de Clean my Calanques !"
      />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête nos actions"
          image={getImage(frontmatter.image)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
              sx={{ textAlign: "center" }}
            >
              <Heading as="h1" color="white">
                Nos actions
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Container
          sx={{ paddingTop: [8, null, 10], paddingBottom: [8, null, 13] }}
          variant="containerLg"
        >
          <FlexGrid
            positions={["stretch", "stretch"]}
            columns={[12, 6, null]}
            gutter={5}
          >
            <Box>
              <Card to="/sensibilisations">
                <Card.Image
                  image={getImage(data.sensibilisations)}
                  imageLoading="eager"
                />
                <Card.Content
                  positions={["center", "flex-start"]}
                  sx={{
                    py: 6,
                    px: [2, 4, 6],
                  }}
                >
                  <Heading as="h2" color="primary" sx={{ marginBottom: 3 }}>
                    Sensibilisations
                  </Heading>
                  <Text as="div">
                    <ReactMarkdown>
                      {frontmatter.sensibilisations.text}
                    </ReactMarkdown>
                  </Text>
                  <Button
                    sx={{
                      width: "285px",
                      textTransform: "uppercase",
                      marginTop: 5,
                    }}
                  >
                    Voir nos sensibilisations
                  </Button>
                </Card.Content>
              </Card>
            </Box>
            <Box>
              <Card to="/evenements">
                <Card.Image
                  image={getImage(data.ramassages)}
                  imageLoading="eager"
                />
                <Card.Content
                  positions={["center", "flex-start"]}
                  sx={{
                    py: 6,
                    px: [2, 4, 6],
                  }}
                >
                  <Heading as="h2" color="primary" sx={{ marginBottom: 3 }}>
                    Ramassages
                  </Heading>
                  <Text as="div">
                    <ReactMarkdown>{frontmatter.ramassages.text}</ReactMarkdown>
                  </Text>
                  <Button
                    sx={{
                      width: "285px",
                      textTransform: "uppercase",
                      marginTop: 5,
                    }}
                  >
                    Voir nos ramassages
                  </Button>
                </Card.Content>
              </Card>
            </Box>
          </FlexGrid>
        </Container>
      </Box>
      <Contact />
    </Page>
  );
};

export default NosActions;

export const query = graphql`
  query NosActionsQueryData {
    sensibilisations: file(
      relativePath: { eq: "sensibilisations-nos-actions.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 900)
      }
    }
    ramassages: file(relativePath: { eq: "ramassages-nos-actions.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 900)
      }
    }
    markdownRemark(fields: { slug: { eq: "/nos-actions/" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        sensibilisations {
          text
        }
        ramassages {
          text
        }
      }
    }
  }
`;
